import styled from "@emotion/styled";
import {
  Button,
  TextareaAutosize,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import monogramBlack from "./monogram-black.svg";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ThemeOptions } from "@mui/material/styles";

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  background-color: #fffbf8;
  color: #202722;
  @media (max-width: 800px) {
    flex-direction: column;
    height: auto;
  }
`;

const Image = styled.img`
  width: 50%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
  position: fixed;
  left: 0;

  transition: all 0.3s;

  @media (max-width: 1100px) {
    width: 40%;
  }

  @media (max-width: 800px) {
    position: relative;
    width: 100%;
    height: 80vh;
  }
`;

const SideTitle = styled.h2`
  margin: 20px 0 10px;
`;

const Content = styled.div`
  padding: 50px 50px;
  position: relative;
  display: grid;
  align-content: center;
  min-height: calc(100vh - 100px);
  height: auto;
  margin-top: auto;
  margin-left: 50%;
  margin-bottom: auto;

  transition: all 0.3s;

  p {
    font-size: 1.1rem;
    line-height: 1.4;
    margin: 0.8em 0;
  }

  a {
    color: black;
    text-decoration: underline;
    transition: all 0.3s;
    &:hover {
      color: #444;
    }
  }

  @media (max-width: 1100px) {
    margin-left: 40%;
    p {
      font-size: 1rem;
      line-height: 1.4;
      margin: 0.8em 0;
    }

    label {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 800px) {
    padding: 50px 30px;
    width: calc(100% - 60px);
    margin-left: auto;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 0;
    min-height: 100vh;
    overflow-x: hidden;
  }
`;

const Centerizer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    max-width: none;
  }
`;

const Logo = styled.img`
  display: block;

  width: 100px;
  height: 100px;
`;

function HomePage() {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Image src={"/hero.jpg"} alt="logo" />
      <Content>
        <Centerizer>
          <Logo src={monogramBlack} alt="logo" />

          <p>Hello!</p>

          <p>
            You are joyously invited to a family dinner in celebration of Ling &
            Xun’s union.
          </p>

          <p>
            Dinner begins at <strong>7:00 PM</strong> <br />
            on <strong>22 October 2024</strong>
          </p>

          <p>
            at{" "}
            <strong>
              <a
                href="https://maps.app.goo.gl/YssXDQqFVc4RcENS8"
                target="_blank"
                rel="noreferrer">
                TungLok Peking Duck (Orchard Central)
              </a>
            </strong>
            , 181 Orchard Road, #07-07, Singapore 238896.
          </p>

          <p>Please note that alcohol will not be provided at this event.</p>

          <StyledButton
            variant="contained"
            color="primary"
            onClick={() => {
              navigate("/rsvp");
            }}>
            RSVP Now
          </StyledButton>
        </Centerizer>
      </Content>
    </Wrapper>
  );
}

const FormikForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 100%;
  max-width: 500px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  flex-grow: 1;
  justify-content: end;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
`;

const FormikField = styled(Field)`
  font-size: 1em;
  font-family: sans-serif;
  width: calc(100% - 30px);
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid black;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const StyledButton = styled(Button)`
  font-size: 1.1em;
  font-family: "Kadwa", serif;
  padding: 10px 20px;

  @media (max-width: 1000px) {
    font-size: 0.9em;
  }
`;

const UnresizableTextarea = styled(TextareaAutosize)`
  resize: none;
`;

const FullWidthToggleButtonGroup = styled(ToggleButtonGroup)`
  width: 100%;

  background-color: #fff;

  &.small-collapse {
    @media (max-width: 1000px) {
      flex-direction: column;

      button {
        border-radius: 5px;
        border: 1px solid #ddd;
        outline: none;
      }
    }
  }
`;

const ToggleButtonGrow = styled(ToggleButton)`
  font-family: "Kadwa", serif;
  font-size: 1em;
  flex-grow: 1;
  padding: 10px 20px;

  @media (max-width: 1000px) {
    font-size: 0.85em;
    padding: 10px 10px;
    width: 100%;
  }
`;

function RSVPPage({ side }: { side: string }) {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Image src={"/hero.jpg"} alt="logo" />
      <Content>
        <Centerizer>
          {side === "" ? (
            <>
              <Logo src={monogramBlack} alt="logo" />
              <p>You are a relative of the...</p>
              <ButtonWrapper>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate("/rsvp/bride");
                  }}>
                  Bride
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate("/rsvp/groom");
                  }}>
                  Groom
                </StyledButton>
              </ButtonWrapper>
            </>
          ) : (
            <>
              <Logo src={monogramBlack} alt="logo" />
              <SideTitle>
                Relative of{" "}
                {side.substring(0, 1).toUpperCase() + side.substring(1)}
              </SideTitle>
              <Formik
                initialValues={{
                  guests: 1,
                  names: [""],
                  dietary_restrictions: [""],
                  email: "",
                  response: "",
                  which_side: "",
                  need_parking: false,
                  comment: "",
                  notes: "",
                  grouped_with: null,
                }}
                onSubmit={async (values) => {
                  const people = [];

                  for (let i = 0; i < values.guests; i++) {
                    if (i === 0) {
                      people.push({
                        name: values.names[i],
                        dietary_restrictions: values.dietary_restrictions[i],
                        email: values.email,
                        need_parking: values.need_parking,
                        comment: values.comment,
                        notes: values.notes,
                        response: values.response,
                        which_side: side,
                      });
                    } else {
                      people.push({
                        name: values.names[i],
                        email: values.email,
                        dietary_restrictions: values.dietary_restrictions[i],
                        response: values.response,
                        which_side: side,
                      });
                    }
                  }

                  try {
                    const response = await fetch(
                      `${process.env.REACT_APP_API_URL}/rsvp`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(people),
                      }
                    );

                    if (!response.ok) {
                      throw new Error("RSVP submission failed");
                    }

                    // Clear form

                    navigate("/success");
                  } catch (error) {
                    console.error(error);
                  }
                }}>
                {({ values, handleChange, isSubmitting }) => (
                  <FormikForm>
                    <FieldWrapper>
                      <FullWidthToggleButtonGroup
                        color="primary"
                        value={values.response}
                        exclusive
                        onChange={(e, value) => {
                          if (!value) return;
                          handleChange({
                            target: { name: "response", value: value },
                          });
                        }}
                        aria-label="RSVP"
                        className="small-collapse">
                        <ToggleButtonGrow value="yes">
                          I will be attending
                        </ToggleButtonGrow>
                        <ToggleButtonGrow value="no">
                          I will not be attending
                        </ToggleButtonGrow>
                        <ToggleButtonGrow value="maybe">
                          I may be attending
                        </ToggleButtonGrow>
                      </FullWidthToggleButtonGroup>
                    </FieldWrapper>
                    {(values.response === "yes" ||
                      values.response === "maybe") && (
                      <>
                        <FieldWrapper>
                          <Label htmlFor="guests">Number of Guests</Label>
                          <FullWidthToggleButtonGroup
                            color="primary"
                            value={`${values.guests}`}
                            exclusive
                            onChange={(e, value) => {
                              if (!value) return;
                              handleChange({
                                target: {
                                  name: "guests",
                                  value: parseInt(value),
                                },
                              });
                            }}
                            aria-label="Guests">
                            <ToggleButtonGrow value="1">
                              Just me
                            </ToggleButtonGrow>
                            <ToggleButtonGrow value="2">2</ToggleButtonGrow>
                            <ToggleButtonGrow value="3">3</ToggleButtonGrow>
                            <ToggleButtonGrow value="4">4</ToggleButtonGrow>
                            <ToggleButtonGrow value="5">5</ToggleButtonGrow>
                          </FullWidthToggleButtonGroup>
                        </FieldWrapper>

                        {[...Array(values.guests || 1)].map((_, i) => (
                          <div
                            key={i}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                            }}>
                            <FieldWrapper>
                              <Label htmlFor={`names[${i}]`}>Name</Label>
                              <FormikField
                                id={`names[${i}]`}
                                name={`names[${i}]`}
                                required
                                placeholder="required"
                              />
                            </FieldWrapper>

                            <FieldWrapper>
                              <Label htmlFor={`dietary_restrictions[${i}]`}>
                                Dietary Restriction(s)
                              </Label>
                              <FormikField
                                id={`dietary_restrictions[${i}]`}
                                name={`dietary_restrictions[${i}]`}
                                placeholder="optional"
                              />
                            </FieldWrapper>
                          </div>
                        ))}

                        <FieldWrapper>
                          <Label htmlFor="email">Contact Email</Label>
                          <FormikField
                            id="email"
                            name="email"
                            type="email"
                            required
                            placeholder="required"
                          />
                        </FieldWrapper>
                        <FieldWrapper>
                          <Label htmlFor="need_parking">
                            Do you need parking
                          </Label>
                          <FullWidthToggleButtonGroup
                            color="primary"
                            value={values.need_parking ? "true" : "false"}
                            exclusive
                            onChange={(e, value) => {
                              handleChange({
                                target: {
                                  name: "need_parking",
                                  value: value === "true",
                                },
                              });
                            }}
                            aria-label="Parking">
                            <ToggleButtonGrow value="true">
                              Yes
                            </ToggleButtonGrow>
                            <ToggleButtonGrow value="false">
                              No
                            </ToggleButtonGrow>
                          </FullWidthToggleButtonGroup>
                        </FieldWrapper>
                      </>
                    )}

                    {values.response === "no" && (
                      <FieldWrapper>
                        <Label htmlFor={`names[0]`}>Name</Label>
                        <FormikField
                          id={`names[0]`}
                          name={`names[0]`}
                          required
                        />
                      </FieldWrapper>
                    )}

                    {!!values.response && (
                      <FieldWrapper>
                        <Label htmlFor="comment">Comments</Label>
                        <FormikField
                          as={UnresizableTextarea}
                          id="comment"
                          name="comment"
                          placeholder="optional"
                        />
                      </FieldWrapper>
                    )}

                    {!!values.response && (
                      <StyledButton
                        style={{ margin: "20px 10px", padding: "10px" }}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}>
                        RSVP
                      </StyledButton>
                    )}
                  </FormikForm>
                )}
              </Formik>
            </>
          )}
        </Centerizer>
      </Content>
    </Wrapper>
  );
}

function SubmittedPage() {
  return (
    <Wrapper>
      <Image src={"/hero.jpg"} alt="logo" />
      <Content>
        <Centerizer>
          <Logo src={monogramBlack} alt="logo" />

          <p
            style={{
              marginTop: "40px",
            }}>
            Thank you for your registration!
          </p>

          <p>
            A confirmation has been sent to your email. Please check your spam
            folder if you can’t find it in your inbox.
          </p>
        </Centerizer>
      </Content>
    </Wrapper>
  );
}

function App() {
  const theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#C9614E",
      },
      secondary: {
        main: "#2D4850",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/rsvp" element={<RSVPPage side={""} />} />
            <Route path="/rsvp/bride" element={<RSVPPage side={"bride"} />} />
            <Route path="/rsvp/groom" element={<RSVPPage side={"groom"} />} />
            <Route path="/success" element={<SubmittedPage />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
